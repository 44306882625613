@import "~antd/dist/antd.css";
.topNav {
  padding-top: 10px;
    position: fixed;
    background: #fff;
    left: 0;
    top: 0;
    right: 0;
    z-index: 999;
}
.banner {
  width: 100%;
  height: 640px;
  text-align: center;
  background: linear-gradient(
    40deg, 
    #443265 0%,   #dc8285 50%,  #f3bb9d 100%
    );
  margin-top: 72px;
}
.banner img {
  width: 1440px;
  height: 640px;
}
.advantage {
  text-align: center;
  height: 466px;
   background-color:#f6f9fd;
}
.advantage img {
  width: 1440px;
  height: 466px;
  background-color: #fff;
}
.staff {
  /* background-color: #e2edf9; */
  text-align: center;
  background-color:#f6f9fd;
}
.mission {
  background-color: #fff;
  text-align: center;
}
.mission img {
  width: 1440px;
  height: 388px;
}
.staff img {
  width: 1440px;
  background-color: #fff;
}
.product {
  text-align: center;
  border: 1px solid #fff;
  box-sizing: border-box;
}
.product .wrap{
  padding-top: 71px;
  margin: auto;
  width: 1440px;
  height: 815px;
  background-color: #fff;
}
.product .wrap .title{
  margin:auto;
  /* background-color: red; */
}
.product .wrap .title img {
  width: 477px;
  height: 142px;
}
.zhaoping {
 text-align: center; 
 background-color:#f6f9fd;
 padding-bottom: 80px;
}
.zhaoping img{
  width: 1190px;
}
.us {
  width: 100%;
  text-align: center;
  background: linear-gradient(
    40deg, 
    #443265 0%,   #dc8285 50%,  #f3bb9d 100%
    );
}
.us img {
  width: 1440px;
  height: 605px;
}
.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: none !important;
}
.zs a:hover {
  color:#FC3B72 !important
}
.zs_code {
  font-size: 16px;
}
.zs_code:hover {
  color:#FC3B72 !important
}
